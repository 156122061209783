<template>
  <div v-if="dati == null">Caricamento in corso...</div>
  <div id="intro" v-if="dati != null">
    <div class="avatar">
      <img v-if="dati.avatar != false" :src="dati.avatar" />
      <img
        v-if="dati.avatar == false"
        src="../../assets/icons/user-white.svg"
      />
    </div>
    <div class="dati">
      <div>
        <div class="name">{{ dati.first_name }} {{ dati.last_name }}</div>
        <div class="bio">{{ dati.bio }}</div>
        <div class="clearfix"></div>
      </div>
      <div
        class="bottoni"
        v-if="myId != 0 && myId != id"
        :class="{ disable: sending }"
      >
        <div class="segui" @click="follow" v-if="!following && !sending">
          {{ $gettext('Segui') }}
        </div>
        <div class="smettisegui" @click="follow" v-if="following && !sending">
          {{ $gettext('Smetti di seguire') }}
        </div>
        <div class="segui disable" v-if="sending">{{ $gettext('elaborazione...') }}</div>
        <div class="contatta">
          <router-link
            :to="{ name: 'Profile sendmessage', params: { to_user: id } }"
          >
            {{ $gettext('Invia email') }}
          </router-link>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div v-if="listing.length == 0">
    <h2>{{ $gettext("Al momento l'utente non ha inserito ricette.") }}</h2>
  </div>
  <div id="recipe" v-if="listing.length > 0">
    <recipe-card
      v-for="item in listing"
      :key="item.id"
      :title="item.title"
      :image="item.image"
      :id="item.id"
      :item="item"
    ></recipe-card>
  </div>
</template>

<script>
import recipeCard from '@/components/recipe/RecipeCard';

export default {
  name: 'profile-other',
  components: { recipeCard },
  data() {
    return {
      id: 0,
      dati: null, //dati del profilo che sto guardando
      myId: 0,
      listing: [],
      user_data: this.$store.getters['auth/getDataProfile'], //dati utente loggato. Non quelli del profilo che sto guardando,
      sending: false,
    };
  },
  methods: {
    getData() {
      this.axios
        .get(this.api.getDataProfileOther + this.id)
        .then((response) => {
          this.dati = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async loadImageURL(idM, idListing) {
      let mediaUrl = this.api.getMediaUrl + idM;

      await fetch(mediaUrl)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            console.log('Error in loading image');
          }
        })
        .then((res) => {
          this.listing[idListing].image =
            res.media_details.sizes.medium_large.source_url;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
    async getRecipe() {
      try {
        let response = await this.axios.get(this.api.getRecipeAuthor + this.id);
        const listing = [];
        console.log("response.data");
        console.log(response.data);
        response.data.forEach((item, index) => {
          listing.push({
            authorName: item.author_name,
            authorId: item.author,
            id: item.id,
            title: item.title.rendered,
            image: '/images/placeholder-768x768.png',
            categoria: '',
            item: item,
          });

          this.loadImageURL(item.featured_media, index);
        });
        this.listing = listing;
      } catch (error) {
        alert(
          $gettext('Errore nel prendere le immagini. Si prega di provare tra qualche minuto'),
        );
        console.log(error);
      }
    },
    follow() {
      if (this.sending) return;

      this.sending = true;

      const type = 'user';
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      const params = {
        id: this.id,
        type,
      };

      let send = {};
      let type_send = '';
      if (this.following) {
        send = this.axios.delete(
          this.api.follow + '?id=' + this.id + '&type=user',
          config,
        );
        type_send = 'delete';
      } else {
        send = this.axios.post(this.api.follow + '?id=' + this.id + '&type=user', params, config);
        type_send = 'add';
      }

      send
        .then(async (response) => {
          this.sending = false;
          this.loading = false;
          switch (type_send) {
            case 'add':
              await this.user_data.follow.user.push(this.id);
              break;

            case 'delete':
              this.user_data.follow.user = this.user_data.follow.user.filter(
                (item) => {
                  return parseInt(item) !== parseInt(this.id);
                },
              );
              break;
          }
          this.$store.commit('auth/setDataProfile', this.user_data);
        })
        .catch((error) => {
          this.loading = false;
          this.sending = false;
          alert(
            $gettext('Operazione al momento non possibile. Si prega di provare tra qualche minuto'),
          );
        });
    },
    invia() {
      this.$router.push({ name: '' });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
    this.getRecipe();
    const tmp = this.$store.getters['auth/getDataProfile'];

    this.myId = tmp.id;
  },
  computed: {
    following() {
      let trovato = -1;
      console.log("following");
      console.log(this.user_data.follow.user);
      this.user_data.follow.user.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });
      if (trovato > -1) return true;
      return false;
    },
  },
};
</script>

<style scoped>
#intro {
  padding: 20px;
  background-color: var(--var-primary-color);
  width: 100%;
}

#intro .avatar {
  width: 20%;
  float: left;
  margin-right: 20px;
}

#intro .avatar img {
  width: 100%;
  max-width: 100%;
  border-radius: 96px;
}

#intro {
  color: white;
}

#intro .dati {
  width: 79%;
  text-align: left;
}

.bottoni a {
  color: white;
  text-decoration: none;
}

#intro .name {
  font-size: 2rem;
  font-weight: 600;
}

.bottoni {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottoni > div {
  border: 1px white solid;
  width: auto;
  padding: 5px;
  float: left;
  min-width: 100px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.bottoni > div:nth-child(odd) {
  margin-right: 20px;
}

.bottoni.disable .segui {
  opacity: 0.8;
}

.smettisegui {
  background-color: var(--var-primary-color);
}
</style>
